import React from 'react';

const AdminLayout = () => {
    return (
        <div>
            Admin Layout
        </div>
    );
};

export default AdminLayout;