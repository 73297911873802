import React, { useState } from 'react';
import { Typography } from 'antd';
import Logo from '../../assets/images/logo.png';

import Login from './Login/Login';
import Loginas from './Loginas/Loginas';
import Signup from './Signup/Signup';
import Styles from './index.module.css';
import { useLocation } from 'react-router-dom';

const { Link } = Typography;

const AuthScreen = () => {
  const location = useLocation()
  const [isLogin, setIsLogin] = useState(true);

  const handleToggleAuthMode = () => {
    setIsLogin((prev) => !prev);
  };

  return (
    <div className={isLogin ? `${Styles.authContainer}` : `${Styles.authContainer} ${Styles.signUp_screen}`}>     

      <div className={isLogin ? `${Styles.wrapperdiv} ${Styles.loginScreenHeight}` : `${Styles.wrapperdiv}`}>        
          <div className={Styles.authWrapper}>
            <img className={Styles.logo} src={Logo} alt="Logo" />
            
            {location.pathname.indexOf('Loginas') > -1 ? <Loginas /> : (isLogin ? <Login /> : <Signup />)}

            <div className={Styles.toggleAuthMode}>
              <Link onClick={handleToggleAuthMode}>
                {isLogin ? "Don't have an account? Register" : 'Already have an account? Login'}
              </Link>
            </div>
          </div>
      </div>
    </div>
  );
};

export default AuthScreen;
