import { lazy } from 'react';

export const MembersLayout = lazy(() => import('../layouts/MembersLayout'));
export const Dashboard = lazy(() => import('../pages/Members/Dashboard/Dashboard'));
export const Orders = lazy(() => import('../pages/Members/Orders/Orders'));
export const NewOrder = lazy(() => import('../pages/Members/Orders/NewOrder/NewOrder'));
export const BulkOrder = lazy(() => import('../pages/Members/Orders/NewOrder/BulkOrder'));
export const MagentoStore = lazy(() => import('../pages/Members/MyStores/MagentoStore/MagentoStore'));
export const ShopifyStore = lazy(() => import('../pages/Members/MyStores/ShopifyStore/ShopifyStore'));
export const UnicomStore = lazy(() => import('../pages/Members/MyStores/UnicomStore/UnicomStore'));
export const VinculumStore = lazy(() => import('../pages/Members/MyStores/VinculumStore/VinculumStore'));
export const WooCommerceStore = lazy(() => import('../pages/Members/MyStores/WooCommerceStore/WooCommerceStore'));
export const Products = lazy(() => import('../pages/Members/Products/Products'));
export const NDR = lazy(() => import('../pages/Members/NDR/NDR'));
export const Reports = lazy(() => import('../pages/Members/Reports/Reports'));
export const Invoices = lazy(() => import('../pages/Members/Invoices/Invoices'));
export const Remittances = lazy(() => import('../pages/Members/Remittances/Remittances'));
export const Profile = lazy(() => import('../pages/Members/Profile/Profile'));
export const Wallet = lazy(() => import('../pages/Members/Wallet/Wallet'));
export const WalletRecharge = lazy(() => import('../pages/Members/WalletRecharge/WalletRecharge'));
export const RateCalculator = lazy(() => import('../pages/Members/RateCalculator/RateCalculator'));
export const ChannelIntegration = lazy(() => import('../pages/Members/ChannelIntegration/ChannelIntegration'));
export const TrackOrder = lazy(() => import('../pages/Members/TrackOrder/TrackOrder'));

export const AdminLayout = lazy(() => import('../layouts/AdminLayout'));
export const AuthScreen = lazy(() => import('../pages/Auth/index')); 