import React, { useState, useContext } from 'react';
import { Form, Input, Button, message } from 'antd';
import { useNavigate } from 'react-router-dom';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import Styles from './Signup.module.css';
import * as authService from '../../../services/authService'; // Import the authService
import AuthContext from '../../../context/auth-context';

const Signup = () => {
  const [form] = Form.useForm();
  const [otpSent, setOtpSent] = useState(false); // New state to track OTP sent status
  const authContext = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleRequestOTP = async (values) => {
    try {
      const { mobile } = values;
      const res = await authService.GET_SIGNUP_OTP(mobile);
      if(res?.error){
        // Display error message
        message.error(res?.error);            
      }else{
        // Set OTP sent status to true
        setOtpSent(true);

        // Display success message
        message.success('An OTP has been sent to your number via WhatsApp');
      }
    } catch (error) {
      // Display error message
      message.error(error.message);
    }
  };

  const handleSignup = async (values) => {
    try {
      const { company_id = "", isemployee = 0, mobile, name, password, password_confirmation, token } = values;
      // Perform signup logic here using the provided values
      // Example: You can make an API call to register the new user
      authContext.onSignup(company_id, isemployee, mobile, name, password, password_confirmation, token);
      console.log(authContext.isLogin);
      setLoading(true);
      if(Number(localStorage.getItem('isLoggedIn'))){
        setLoading(false);
        const isEmployee = Number(localStorage.getItem('isemployee'));
        if (Number(isEmployee) === 0) {
          navigate('../layouts/MembersLayout');
        } 
        else if (Number(isEmployee) === 1) {
          navigate('/admin-dashboard');
        }
        message.success('Login successful'); 
      }
      if(!authContext.isLogin){
        setLoading(false);
      }
    } catch (error) {
      // Display error message
      message.error(error.message);
    }
  };

  return (
    <div className={Styles.signupContainer}>
      <Form
        form={form}
        name="signupForm"
        onFinish={handleSignup}
        className={Styles.signupForm}
      >
        <Form.Item
          name="name"
          rules={[{ required: true, message: 'Please enter your name!' }]}
        >
          <Input placeholder="Name" />
        </Form.Item>

        <Form.Item
          name="mobile"
          rules={[
            { required: true, message: 'Please enter your 10-digit mobile number!' },
            { len: 10, message: 'Mobile number must be 10 digits!' },
            { pattern: /^[0-9]*$/, message: 'Please enter only numbers!' }
          ]}
        >
          <Input placeholder="10-digit Mobile Number" />
        </Form.Item>

        {!otpSent && (
          <>
            <div className={Styles.whatsappInfo}>
              <strong>Note:</strong> Please enter your WhatsApp Number as OTP will be sent via WhatsApp only.
            </div>

            <Form.Item>
              <Button type="primary" onClick={() => handleRequestOTP(form.getFieldsValue())}>
                Request OTP
              </Button>
            </Form.Item>
          </>
        )}

        {otpSent && (
          <>
            <div className={Styles.whatsappInfo}>
            <strong>Update:</strong> You should receive an OTP on your WhatsApp.<br/>If you have not received the OTP yet, please double check the number you have entered.<br/><br/>
            <strong>Or</strong>
            </div>

            <Form.Item>
              <Button type="primary" onClick={() => handleRequestOTP(form.getFieldsValue())}>
                Request OTP Again
              </Button>
            </Form.Item>
          </>
        )}

        <Form.Item
            name="token"
            rules={[{ required: true, message: 'Please enter the OTP sent to your mobile!' }]}
          >
            <Input placeholder="Enter OTP" />
        </Form.Item>

        <Form.Item
          name="password"
          rules={[
                { required: true, message: 'Please enter your password!' },
                { min: 8, message: 'Password must contain minimum 8 characters' }
            ]}
        >
          <Input.Password
            placeholder="Password"
            iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
          />
        </Form.Item>

        <Form.Item
          name="password_confirmation"
          dependencies={['password']}
          rules={[
            { required: true, message: 'Please confirm your password!' },            
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject('The two passwords do not match!');
              },
            }),
          ]}
        >
          <Input.Password
            placeholder="Confirm Password"
            iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
          />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" className={Styles.signupButton}>
            Sign Up
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Signup;
