//NewMemberNDS - New Members who has Registered but Documents Submission is not yet complete.
import React, {useState} from 'react';
import { useNavigate } from 'react-router-dom';
import Styles from './NewMember.module.css';
import Swal from 'sweetalert2';
import * as authService from '../../services/authService';
import { Card, Form, Input, Button, Typography, Upload } from 'antd';
import { UploadOutlined, CheckOutlined, CloseOutlined, DeleteOutlined, CloudSyncOutlined } from '@ant-design/icons';
import ErrImg from '../../assets/images/image_error.png';

const { Title } = Typography;


const NewMemberNDS = ({ response, handleLogout }) => {
  const navigate = useNavigate();
  const [payload, setPayload] = useState(response);
  
  const [data, setData] = useState({
    customer_name : response?.customer_name,
    customer_code : response?.customer_code,
    name: response?.name,
    address : response?.address[0]?.address,
    aadhar : response?.aadhar,
    gstin : response?.gstin,
    account_no : response?.account_no,
    bank : response?.bank,
    account_type : response?.account_type,
    account_ifsc : response?.account_ifsc,
    bank_address : response?.bank_address,
    adhar_image : response?.adhar_image,
    cheque_image : response?.cheque_image,
    gstin_image : response?.gstin_image
  });

  const removeDocImage =  async(imageName) => {
    console.log(imageName);
    
    switch(imageName){
      case 'adhar_image':
        setPayload(async(prev)=>{
          prev.adhar_image = '';
          console.log('Delete Payload', payload);
          submitData(payload);
        }); 
        break;

      case 'gstin_image':         
        setPayload(async(prev)=>{
          prev.gstin_image = '';
          console.log('Delete Payload', payload);
          submitData(payload);
        });
        break;

      case 'cheque_image': 
        setPayload(async(prev)=>{
          prev.cheque_image = '';
          console.log('Delete Payload', payload);
          submitData(payload);
        });
        break;
    }
  }
  
  const onFinish = async (values) => {
    setPayload((prev)=>({...prev, values}));
    if(values.adhar_image !== null || values.adhar_image === ""){
        setPayload((prev)=>({...prev, adhar_image: values.adhar_image?.fileList[0].originFileObj}));
    }
    if(values.cheque_image !== null || values.cheque_image === ""){
        setPayload((prev)=>({...prev, cheque_image: values.cheque_image?.fileList[0].originFileObj}));
    }
    if(values.gstin_image !== null || values.gstin_image === ""){
        setPayload((prev)=>({...prev, gstin_image: values.gstin_image?.fileList[0].originFileObj}));
    }    
    submitData(payload);
  };
  
  const submitData = async (payload) => {
    console.log('Payload:', payload);

    try {
      const response = await authService.USER_PROFILE_UPDATE_WITH_FILE(payload, payload?.id);    
      console.log(response);
      const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.onmouseenter = Swal.stopTimer;
            toast.onmouseleave = Swal.resumeTimer;
          }
      });
      Toast.fire({
          icon: "success",
          title: "Data submitted/updated successfully!"
      });
      // setTimeout(()=>{
      //   window.location.reload();
      // },1000);
    } 
    catch (error) {
        console.log(error);
    }
  }

  const uploadProps = {
    beforeUpload: () => true,
    onChange: (info) => {
      console.log(info.file);
    },
  };

  return (
    <div className={Styles.container}>
      <div className={Styles.page_header}>
        <p>Hi {data?.customer_name}, Your Account verification is under process.</p>
        <span>Please review your submitted data carefully and re-submit if there is any mistake or missing data, so that your account is verified without any delay. </span>
      </div>
      <div className={Styles.data_container}>
        <Card>
          <Title level={4}>Customer & Business Names</Title>
          <p>
            { data?.customer_name === "" 
              ? <span style={{color: 'red', fontSize: 18}}><CloseOutlined />&nbsp;</span> 
              : <span style={{color: 'green', fontSize: 18}}><CheckOutlined />&nbsp;</span> 
            }
            <label>Customer Name:</label>
            { data?.customer_name === "" 
              ? <span style={{color: 'red'}}>Data Missing</span> 
              : data?.customer_name
            }
          </p>
          <p>
            { data?.customer_code === "" 
              ? <span style={{color: 'red', fontSize: 18}}><CloseOutlined />&nbsp;</span> 
              : <span style={{color: 'green', fontSize: 18}}><CheckOutlined />&nbsp;</span> 
            }
            <label>Customer Code:</label>
            { data?.customer_code === "" 
              ? <span style={{color: 'red'}}>Data Missing</span> 
              : data?.customer_code
            }
          </p>
          <p>
            { data?.name === "" 
              ? <span style={{color: 'red', fontSize: 18}}><CloseOutlined />&nbsp;</span> 
              : <span style={{color: 'green', fontSize: 18}}><CheckOutlined />&nbsp;</span> 
            }
            <label>Company Name:</label>
            { data?.name === "" 
              ? <span style={{color: 'red'}}>Data Missing</span> 
              : data?.name
            }
          </p>
        </Card>

        <Card>
          <Title level={4}>Address</Title>
          <p>
            { data?.address === "" 
              ? <span style={{color: 'red', fontSize: 18}}><CloseOutlined />&nbsp;</span> 
              : <span style={{color: 'green', fontSize: 18}}><CheckOutlined />&nbsp;</span> 
            }
            <label>Address:</label>
            { data?.address === "" 
              ? <span style={{color: 'red'}}>Data Missing</span> 
              : data?.address
            }
          </p>
        </Card>

        <Card>
          <Title level={4}>Identity Details</Title>
          <p>
            { data?.aadhar === "" 
              ? <span style={{color: 'red', fontSize: 18}}><CloseOutlined />&nbsp;</span> 
              : <span style={{color: 'green', fontSize: 18}}><CheckOutlined />&nbsp;</span> 
            }
            <label>Aadhar Number:</label>
            { data?.aadhar === "" 
              ? <span style={{color: 'red'}}>Data Missing</span> 
              : data?.aadhar
            }
          </p>
          <p>
            { data?.gstin === "" 
              ? <span style={{color: 'red', fontSize: 18}}><CloseOutlined />&nbsp;</span> 
              : <span style={{color: 'green', fontSize: 18}}><CheckOutlined />&nbsp;</span> 
            }
            <label>GST Number:</label>
            { data?.gstin === "" 
              ? <span style={{color: 'red'}}>Data Missing</span> 
              : data?.gstin
            }
          </p>
        </Card>

        <Card>
          <Title level={4}>Bank Account Details</Title>
          <p>
            { data?.account_no === "" 
              ? <span style={{color: 'red', fontSize: 18}}><CloseOutlined />&nbsp;</span> 
              : <span style={{color: 'green', fontSize: 18}}><CheckOutlined />&nbsp;</span> 
            }
            <label>Account Number:</label>
            { data?.account_no === "" 
              ? <span style={{color: 'red'}}>Data Missing</span> 
              : data?.account_no
            }
          </p>
          <p>
            { data?.bank === "" 
              ? <span style={{color: 'red', fontSize: 18}}><CloseOutlined />&nbsp;</span> 
              : <span style={{color: 'green', fontSize: 18}}><CheckOutlined />&nbsp;</span> 
            }
            <label>Bank Name:</label>
            { data?.bank === "" 
              ? <span style={{color: 'red'}}>Data Missing</span> 
              : data?.bank
            }
          </p>
          <p>
            { data?.account_type === "" 
              ? <span style={{color: 'red', fontSize: 18}}><CloseOutlined />&nbsp;</span> 
              : <span style={{color: 'green', fontSize: 18}}><CheckOutlined />&nbsp;</span> 
            }
            <label>Account Type:</label>
            { data?.account_type === "" 
              ? <span style={{color: 'red'}}>Data Missing</span> 
              : data?.account_type
            }
          </p>
          <p>
            { data?.account_ifsc === "" 
              ? <span style={{color: 'red', fontSize: 18}}><CloseOutlined />&nbsp;</span> 
              : <span style={{color: 'green', fontSize: 18}}><CheckOutlined />&nbsp;</span> 
            }
            <label>IFSC Code:</label>
            { data?.account_ifsc === "" 
              ? <span style={{color: 'red'}}>Data Missing</span> 
              : data?.account_ifsc
            }
          </p>
          <p>
            { data?.bank_address === "" 
              ? <span style={{color: 'red', fontSize: 18}}><CloseOutlined />&nbsp;</span> 
              : <span style={{color: 'green', fontSize: 18}}><CheckOutlined />&nbsp;</span> 
            }
            <label>Bank Address:</label>
            { data?.bank_address === "" 
              ? <span style={{color: 'red'}}>Data Missing</span> 
              : data?.bank_address
            }
          </p>
        </Card>

        <Card>
          <Title level={4}>
            Document Uploads
            <Button 
              icon={<CloudSyncOutlined />}
              type='primary'
              danger
              onClick={()=>window.location.reload()}
              style={{marginLeft: '20px'}}
            />
          </Title>
          <p>
            { data?.adhar_image === "" || data?.adhar_image === null
              ? <span style={{color: 'red', fontSize: 18}}><CloseOutlined />&nbsp;</span> 
              : <span style={{color: 'green', fontSize: 18}}><CheckOutlined />&nbsp;</span> 
            }
            <label>Aadhar Card</label>
            { data?.adhar_image === "" || data?.adhar_image === null
              ? <span style={{color: 'red'}}>Data Missing</span> 
              : <div className={Styles.doc_image_wrapper}>
                  <div className={Styles.doc_image_remove}>
                    <Button onClick={()=>{removeDocImage('adhar_image')}} type="primary" size="small" icon={<DeleteOutlined />} danger/>
                  </div>
                  <img src={data?.adhar_image} onError={e=>e.target.src = `${ErrImg}`} className={Styles.doc_image} alt='Adhaar-Card-Image' />
                </div>
            }
          </p>
          <p>
            { data?.gstin_image === "" || data?.gstin_image === null
              ? <span style={{color: 'red', fontSize: 18}}><CloseOutlined />&nbsp;</span> 
              : <span style={{color: 'green', fontSize: 18}}><CheckOutlined />&nbsp;</span> 
            }
            <label>GST Certificate</label>
            { data?.gstin_image === "" || data?.gstin_image === null
              ? <span style={{color: 'red'}}>Data Missing</span> 
              : <div className={Styles.doc_image_wrapper}>
                  <div className={Styles.doc_image_remove}>
                    <Button onClick={()=>{removeDocImage('gstin_image')}} type="primary" size="small" icon={<DeleteOutlined />} danger/>
                  </div>
                  <img src={data?.gstin_image} onError={e=>e.target.src = `${ErrImg}`} className={Styles.doc_image} alt='GST-Certificate-Image' />
                </div>
            }
          </p>
          <p>
            { data?.cheque_image === "" || data?.cheque_image === null
              ? <span style={{color: 'red', fontSize: 18}}><CloseOutlined />&nbsp;</span> 
              : <span style={{color: 'green', fontSize: 18}}><CheckOutlined />&nbsp;</span> 
            }
            <label>Cancelled Cheque Image</label>
            { data?.cheque_image === "" || data?.cheque_image === null
              ? <span style={{color: 'red'}}>Data Missing</span> 
              : <div className={Styles.doc_image_wrapper}>
                  <div className={Styles.doc_image_remove}>
                    <Button onClick={()=>{removeDocImage('cheque_image')}} type="primary" size="small" icon={<DeleteOutlined />} danger/>
                  </div>
                  <img src={data?.cheque_image} onError={e=>e.target.src = `${ErrImg}`} className={Styles.doc_image} alt='Cancelled-Cheque-Image' />
                </div>
            }
          </p>
        </Card>
      </div> 

      <div className={Styles.missing_data_container}>
        <Title level={3}>Please provide following missing details for your account verification</Title>
        <Form onFinish={onFinish}>
          {Object.entries(data).map(([key, value]) => {
            if (value === null || value === '') {
              return (
                <Form.Item key={key} name={key}>
                  {key.includes('_image') ? (
                    <Upload {...uploadProps}>
                      <Button icon={<UploadOutlined />}>Upload {key.replace('_image', '')} Image</Button>
                    </Upload>
                  ) : (
                    <Input placeholder={key.replace(/_/g, ' ').toUpperCase()} />
                  )}
                </Form.Item>
              );
            }
            return null;
          })}

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
            <div style={{ marginTop: 8, fontSize: 10, color: '#858585' }}>[ Double Click to Submit ]</div>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default NewMemberNDS;
