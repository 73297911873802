import React, { useContext, useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from "react-router-dom";
import  './Sidebar.css';
import sidebarBG from '../../assets/images/sidebar_bg.jpg';
import WooComLogo from '../../assets/images/WooCom_Logo.png';
import ShopifyLogo from '../../assets/images/Shopify_Logo.png';
import UnicomLogo from '../../assets/images/unicommerce_white_logo.png';
import VinculumLogo from '../../assets/images/vinculum_white_logo.png';
import AuthContext from '../../context/auth-context';
import SidebarContext from '../../context/SidebarContext';
import { Sidebar, Menu, SubMenu, MenuItem, menuClasses } from "react-pro-sidebar";
import { Avatar } from 'antd';
import * as authService from '../../services/authService'; 

import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UserOutlined,
  AppstoreOutlined,
  ShoppingCartOutlined,
  BlockOutlined,
  ExceptionOutlined,
  ReconciliationOutlined,
  FileOutlined,
  DollarOutlined,
  CalculatorOutlined,
  CustomerServiceFilled,
  ShopOutlined,
  PoweroffOutlined
} from '@ant-design/icons';



const SidebarMenu = (props) => {
  const {customerId, customerName} = props;
  const authCtx = useContext(AuthContext);
  const sidebarContext = useContext(SidebarContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [showLoader, setShowLoader] = useState(false);

  const openSupportLink = () => {
    window.open("https://support.optnship.com/login", "_blank");
  };

  const getIntegrationData = async() => {
      try{
          const resp = await authService.GET_STORES_BY_CUSTOMER_ID(customerId);
          console.log(resp);
          localStorage.setItem('integrations', JSON.stringify(resp));
          setShowLoader(false);
      }
      catch(error){
          console.log(error);
          setShowLoader(false);
      }
  }

  useEffect(()=>{
      setShowLoader(true);
      getIntegrationData();
  },[]);

  
  return (
      <Sidebar 
        className="app" 
        collapsed={sidebarContext.menuCollapsed}
        image={sidebarBG}
        onBackdropClick={() => sidebarContext.setMenuCollapsedValue(false)}
        customBreakPoint="767px"
      >
        <Menu 
          menuItemStyles={{
            button: ({ level, active }) => {
              if (level === 0)
                return {
                  backgroundColor: active ? 'rgba(255,255,255,0.35)' : 'transparent',
                };
            },
          }}
        >
          <MenuItem className="info_wrapper" icon={<Avatar style={{ backgroundColor: '#1A237E' }} icon={<UserOutlined />} />}>
            <div className={!sidebarContext.menuCollapsed ? 'user_info' : 'user_info hidden'}>
              <div className='user_info_welcome'>Welcome to 
                <span className='ship_word'>OPT</span>
                <span style={{color:'#a1b6ff'}}>N</span>
                <span className='bullett_word'>SHIP</span>
              </div>
              <div className='user_info_name'>{customerName}</div>
            </div>
            
          </MenuItem>

          <MenuItem
            component={<Link to="/dashboard" className="link" />}
            icon={<AppstoreOutlined />}
            active={location.pathname === '/dashboard'}
          > 
            Dashboard 
          </MenuItem>

          <MenuItem
            component={<Link to="/order-management" className="link" />}
            icon={<ShoppingCartOutlined />}
            active={location.pathname === '/order-management'}
          > 
            Orders 
          </MenuItem> 

          {localStorage.getItem('integrations') !== null && (
              <SubMenu 
                label="My Stores"
                icon={<ShopOutlined />}
                active={location.pathname === '/my-stores'}
                rootStyles={{
                  ['& > .' + menuClasses.subMenuContent]: {
                    backgroundColor: 'rgba(0, 0, 0, 0.8)',
                    color: '#fff',
                  },
                }}
              >
                {JSON.parse(localStorage.getItem('integrations'))?.length > 0 && (
                  JSON.parse(localStorage.getItem('integrations')).map((store, index)=>{
                    if(store?.company_name === 'SHOPIFY'){
                      return(
                        <MenuItem 
                          component={<Link to="/my-stores/shopify" className="link" />} 
                          active={location.pathname === '/my-stores/shopify'}
                          className={location.pathname === '/my-stores/shopify' ? `submenu_active`: ''}
                        > 
                          <img src={ShopifyLogo} alt="Shopify" />
                        </MenuItem>
                      )
                    }
                  })
                )}

                {JSON.parse(localStorage.getItem('integrations'))?.length > 0 && (
                  JSON.parse(localStorage.getItem('integrations')).map((store, index)=>{
                    if(store?.company_name === 'WOOCOMMERCE'){
                      return(
                        <MenuItem 
                          component={<Link to="/my-stores/woo-commerce" className="link" />} 
                          active={location.pathname === '/my-stores/woo-commerce'}
                          className={location.pathname === '/my-stores/woo-commerce' ? `submenu_active`: ''}
                        > 
                          <img src={WooComLogo} alt="Woo-Commerce" />
                        </MenuItem>
                      )
                    }
                  })
                )}
                  
                {JSON.parse(localStorage.getItem('integrations'))?.length > 0 && (
                  JSON.parse(localStorage.getItem('integrations')).map((store, index)=>{
                    if(store?.company_name === 'UNICOMMERCE'){
                      return(
                        <MenuItem 
                          component={<Link to="/my-stores/unicommerce" className="link" />} 
                          active={location.pathname === '/my-stores/unicommerce'}
                          className={location.pathname === '/my-stores/unicommerce' ? `submenu_active`: ''}
                        > 
                          <img src={UnicomLogo} alt="UniCommerce" />
                        </MenuItem>
                      )
                    }
                  })
                )}
                  

                {JSON.parse(localStorage.getItem('integrations'))?.length > 0 && (
                  JSON.parse(localStorage.getItem('integrations')).map((store, index)=>{
                    if(store?.company_name === 'VINCULUM'){
                      return(
                        <MenuItem 
                          component={<Link to="/my-stores/vinculum" className="link" />} 
                          active={location.pathname === '/my-stores/vinculum'}
                          className={location.pathname === '/my-stores/vinculum' ? `submenu_active`: ''}
                        > 
                          <img src={VinculumLogo} alt="Viculum" />
                        </MenuItem>
                      )
                    }
                  })
                )}
              </SubMenu>
          )}
          

          <MenuItem
            component={<Link to="/our-products" className="link" />}
            icon={<BlockOutlined />}
            active={location.pathname === '/our-products'}
          > 
            Products 
          </MenuItem>

          <MenuItem
            component={<Link to="/ndr-management" className="link" />}
            icon={<ExceptionOutlined />}
            active={location.pathname === '/ndr-management'}
          > 
            NDR 
          </MenuItem>

          <MenuItem
            component={<Link to="/reports" className="link" />}
            icon={<ReconciliationOutlined />}
            active={location.pathname === '/reports'}
          > 
            Reports 
          </MenuItem>

          <MenuItem
            component={<Link to="/invoice-reports" className="link" />}
            icon={<FileOutlined />}
            active={location.pathname === '/invoice-reports'}
          > 
            Invoices 
          </MenuItem>
          
          <MenuItem
            component={<Link to="/remittances" className="link" />}
            icon={<DollarOutlined />}
            active={location.pathname === '/remittances'}
          > 
            Remittances 
          </MenuItem>

          <MenuItem
            component={<Link to="/rate-calculator" className="link" />}
            icon={<CalculatorOutlined />}
            active={location.pathname === '/rate-calculator'}
          > 
            Rate Calculator 
          </MenuItem>
         
          <MenuItem
            onClick={openSupportLink}
            icon={<CustomerServiceFilled />}
          > 
            Support 
          </MenuItem>
        </Menu>
      </Sidebar>
  );
};

export default SidebarMenu;
