import React, { useState, useEffect, Suspense, useContext } from 'react';
import Styles from './MembersLayout.module.css';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import * as authService from '../services/authService';
import AuthContext from '../context/auth-context';

import { Spin } from 'antd';

import Header from '../components/common/Header';
import SidebarContext from '../context/SidebarContext';
import SidebarMenu from '../components/common/Sidebar';
import Footer from '../components/common/Footer';
import MobileMenu from '../components/common/MobileMenu';
import NewMemberNDS from './NewMembers/NewMemberNDS';

import {
    Dashboard,
    Orders,
    NewOrder,
    BulkOrder,
    MyStores,
    MagentoStore,
    ShopifyStore,
    WooCommerceStore,
    UnicomStore,
    VinculumStore,
    Products,
    NDR,
    Reports,
    Invoices,
    Remittances,
    Profile,
    Wallet,
    WalletRecharge,
    ChannelIntegration,
    TrackOrder,
    RateCalculator,
} from '../router/Routes';


const MembersLayout = () => {
    const [loading, setLoading] = useState(true);
    const [customerData, setCustomerData] = useState(null);
    const [isVerifiedCustomer, setIsVerifiedCustomer] = useState(false);
    const [isAllDocumentSubmitted, setIsAllDocumentSubmitted] = useState(false);
    const [isAadharSubmitted, setIsAadharSubmitted] = useState(false);
    const [isGSTSubmitted, setIsGSTSubmitted] = useState(false);
    const [isBankAcSubmitted, setIsBankAcSubmitted] = useState(false);

    const sidebarContext = useContext(SidebarContext);
    const location = useLocation();

    const authContext = useContext(AuthContext);
    const navigate = useNavigate();
    const handleLogout = ()=> {
        authContext.onLogout();
        navigate('/');
    }

    const access_config = JSON.parse(localStorage.getItem('access_config'));   
    
    useEffect(()=>{
        getCustomerData();         
        setTimeout(()=>{
            setLoading(false);
        }, 2000);        
    },[]);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
    }, [location]);   
     

    const getCustomerData = async ()=>{
        try{
            const response = await authService.GET_CUSTOMER_DATA(localStorage.getItem('customer_id')); 
            //console.log(response);
            setCustomerData((prev) => ({ ...prev, ...response }));  
            localStorage.setItem('CUSTOMER_DATA', JSON.stringify(response));        
            
            if(response?.isverified === 1){
                setIsVerifiedCustomer(true);
                access_config.show_header_collapse_btn = true;
                access_config.show_header_search_bar_desktop = true;
                access_config.show_header_search_btn_mobile = true;
                access_config.show_header_recharge_btn = true;
                access_config.show_header_avatar = true;
                access_config.show_header_avatar_drop_down_menu = true;
                
                localStorage.setItem('access_config', JSON.stringify(access_config));
            }
            if(response?.isverified === 0){
                console.log(localStorage.getItem('isLoggedIn'));
                setIsVerifiedCustomer(false);
                access_config.show_header_collapse_btn = false;
                access_config.show_header_search_bar_desktop = false;
                access_config.show_header_search_btn_mobile = false;
                access_config.show_header_recharge_btn = false;
                access_config.show_header_avatar = false;
                access_config.show_header_avatar_drop_down_menu = true;
                
                localStorage.setItem('access_config', JSON.stringify(access_config)); 

                console.log('------------------------------------------------------------------');
                console.log('Cutomer Name :',response?.customer_name);
                console.log('Customer Code :',response?.customer_code);
                console.log('Company Name :',response?.name);
                console.log('Address :',response?.address[0]);
                console.log('------------------------------------------------------------------');
                console.log('Aadhar Number :',response?.aadhar);
                console.log('GST Number: ',response?.gstin);
                console.log('------------------------------------------------------------------');
                console.log('Account Number: ',response?.account_no);
                console.log('Bank Name: ',response?.bank);
                console.log('Account Type: ',response?.account_type);
                console.log('IFSC Code: ',response?.account_ifsc);
                console.log('Bank Address: ',response?.bank_address);
                console.log('------------------------------------------------------------------');
                console.log('********************************');
                console.log('Uploaded Section'); 
                console.log('Aadhar Card Image: ',response?.adhar_image);               
                console.log('GST Certificate Image: ',response?.gstin_image);                
                console.log('Cancelled Cheque Image: ',response?.cheque_image);
                console.log('********************************');
                console.log('------------------------------------------------------------------');

                if(!!response?.aadhar && !!response?.adhar_image){
                    setIsAadharSubmitted(true);
                }
                if(!!response?.gstin && !!response?.gstin_image){
                    setIsGSTSubmitted(true);
                }
                if(!!response?.account_no && !!response?.cheque_image){
                    setIsBankAcSubmitted(true);
                }

                if(!!response?.aadhar && !!response?.adhar_image && !!response?.gstin && !!response?.gstin_image && !!response?.account_no && !!response?.cheque_image){
                    setIsAllDocumentSubmitted(true);
                }
            }                       
        }
        catch(error){
            console.log(error);
        } 
    }

    

    return (
        <>  
            {loading && (
                <div style={{ width: '100vw', height: '100vh', display: "grid", placeContent: 'center'}}>
                    <Spin size="large" />
                </div>
            )}

            {(!loading && isVerifiedCustomer) && (
                <div style={{ display: "flex", position: 'relative'}}> 
                    <SidebarMenu customerId={customerData?.id} customerName= {customerData?.name} />
                    <div className={!sidebarContext.menuCollapsed ? `${Styles.content_area}` : `${Styles.content_area} ${Styles.collapsed_menu}`}>
                        <div className={Styles.header}>
                            <Header customerName= {customerData?.name}/>
                        </div>
                        <div className={Styles.main_content}> 
                            <div className={Styles.main_content_member}>
                                <Suspense fallback={<div>Loading...</div>}>
                                    <Routes>
                                        <Route path="/" element={<Dashboard />} />
                                        <Route path="/Loginas" element={<Dashboard />} />
                                        <Route path="/dashboard" element={<Dashboard />} />
                                        <Route path="/order-management/" element={<Orders />} />
                                        <Route path="/order-management/:tab" element={<Orders />} />
                                        <Route path="/new-order" element={<NewOrder />} />
                                        <Route path="/create-bulk-order" element={<BulkOrder />} />
                                        <Route path="/my-stores/woo-commerce" element={<WooCommerceStore />} />
                                        <Route path="/my-stores/shopify" element={<ShopifyStore />} />
                                        <Route path="/my-stores/unicommerce" element={<UnicomStore />} />
                                        <Route path="/my-stores/vinculum" element={<VinculumStore />} />
                                        <Route path="/my-stores/magento" element={<MagentoStore />} />
                                        <Route path="/our-products" element={<Products />} />
                                        <Route path="/ndr-management" element={<NDR />} />
                                        <Route path="/reports" element={<Reports />} />
                                        <Route path="/invoice-reports" element={<Invoices />} />
                                        <Route path="/remittances" element={<Remittances />} />
                                        <Route path="/my-profile" element={<Profile />} />  
                                        <Route path="/my-wallet" element={<Wallet />} />
                                        <Route path="/wallet-recharge" element={<WalletRecharge />} />  
                                        <Route path="/channel-integration" element={<ChannelIntegration />} /> 
                                        <Route path="/track-order" element={<TrackOrder />} />  
                                        <Route path="/rate-calculator" element={<RateCalculator />} />     
                                    </Routes>
                                </Suspense>
                            </div>

                            <div className={Styles.main_content_footer}>
                                <Footer />
                            </div>
                        </div>
                        <MobileMenu />    
                    </div>
                </div>
            )}

            {(!loading && !isVerifiedCustomer) && (
                <>
                <div>
                    <Header customerName= {customerData?.name} />
                        <NewMemberNDS response= {customerData} handleLogout = {handleLogout}/>                    
                    <Footer />
                </div>
                
                </>
            )}
        </>
    );
};

export default MembersLayout;
