import React, { createContext, useState } from 'react';

const SidebarContext = createContext({
  menuCollapsed: false,  
  toggleCollapsed: () => {},
  setMenuCollapsedValue: () => {}
});

export const SidebarContextProvider = (props) => {
  const [menuCollapsed, setMenuCollapsed] = useState(false);

  const toggleCollapsed = () => {
    setMenuCollapsed((prevCollapsed) => !prevCollapsed);
  };

  const setMenuCollapsedValue = (value) => {
    setMenuCollapsed(value);
  };
  
  return (
    <SidebarContext.Provider value={{ menuCollapsed, toggleCollapsed, setMenuCollapsedValue }}>
      {props.children}
    </SidebarContext.Provider>
  );
};

export default SidebarContext;