import React, { useState } from 'react';
import { Select } from 'antd';

const { Option } = Select;

const CustomerSelect = ({ customers, onSelect }) => {
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  //console.log(customers);

  const handleChange = (value) => {
    setSelectedCustomer(value);
    onSelect(value);
  };

  return (
    <Select
      showSearch
      style={{ width: 200 }}
      placeholder="Select a customer"
      optionFilterProp="children"
      onChange={handleChange}
      filterOption={(input, option) =>
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      value={selectedCustomer}
    >
      {customers?.map((customer) => (
        <Option key={customer.id} value={customer.id}>
          {customer.name}
        </Option>
      ))}
    </Select>
  ); 
};

export default CustomerSelect;