import { FastRewind } from '@mui/icons-material';
import axios from 'axios';
import Swal from 'sweetalert2'

const axiosClient = axios.create({
    baseURL: "https://core.optnship.com/api/"
    // baseURL: "https://stagecore.optnship.com/api/"
});

const skipList = ["resetPassword", "requestOTP", "register", "profileUpdate"];

const isSkipList = (requestUrl) => {
    let res = false;
    skipList.forEach(element => {
        if(requestUrl.indexOf(element) !== -1){
            res = true;
        }
    });
    return res;
}

axiosClient.interceptors.request.use(function (config) {
    const token = localStorage.getItem('token');
    //config.headers.Authorization =  token ? `Bearer ${token}` : '';
    if(!isSkipList(config?.url)){
        config.headers.Authorization =  token ? `Bearer ${token}` : '';
    }
    
    
    return config;
});
  
axiosClient.interceptors.response.use(
    (response) => {
      if(!isSkipList(response.request.responseURL) && response?.data?.error){
        Swal.fire({
            icon: "error",
            title: "Error",
            text: `${response.data.error}`,
            footer: 'Please reload and try again.'
        });
        return Promise.reject('Error: ' + response.data.error);
      }
      return response;
    },
    (err) => {
        if(err.response.status == 401){
            localStorage.clear();
            window.location.reload();
        }
        return Promise.reject('Error: ' + err);
    }
);
  
export default axiosClient;

