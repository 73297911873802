import React, { useState, useContext  } from 'react';
import Styles from './MobileMenu.module.css';
import { Link, useLocation } from 'react-router-dom';


import ShopifyLogo from '../../assets/images/Shopify_Logo.png';
import WooComLogo from '../../assets/images/WooCom_Logo.png';
import UnicomLogo from '../../assets/images/unicommerce_white_logo.png';
import VinculumLogo from '../../assets/images/vinculum_white_logo.png';

import { Drawer } from 'antd';
import {
    AppstoreOutlined,
    ShoppingCartOutlined,
    FieldTimeOutlined,
    BlockOutlined,
    MenuOutlined,
    CloseCircleOutlined,
    ExceptionOutlined,
    ReconciliationOutlined,
    FileOutlined,
    DollarOutlined,
    CalculatorOutlined,
    CustomerServiceFilled,
    PoweroffOutlined
} from '@ant-design/icons';

const MobileMenu = () => {
    const location = useLocation();

    const [open, setOpen] = useState(false);
    const showDrawer = () => {
        setOpen(true);
    };
    const onClose = () => {
        setOpen(false);
    };

    const openSupportLink = () => {
        window.open("https://support.optnship.com/login", "_blank");
        setOpen(false);
    };

    return(
        <>
            <div className={Styles.mobile_menu_wrapper}>
                <div className={location.pathname === '/dashboard'?  `${Styles.menu_item_wrapper} ${Styles.active}` : `${Styles.menu_item_wrapper}`}>
                    <AppstoreOutlined style={{fontSize: '24px'}} />
                    <Link to="/dashboard">Dashboard</Link>
                </div>

                <div className={location.pathname === '/order-management'?  `${Styles.menu_item_wrapper} ${Styles.active}` : `${Styles.menu_item_wrapper}`}>
                    <ShoppingCartOutlined style={{fontSize: '24px'}} />
                    <Link to="/order-management">Orders</Link>
                </div>

                <div className={location.pathname === '/ndr-management'?  `${Styles.menu_item_wrapper} ${Styles.active}` : `${Styles.menu_item_wrapper}`}>
                    <ExceptionOutlined style={{fontSize: '24px'}} />
                    <Link to="/ndr-management">NDR</Link>
                </div>

                <div className={Styles.menu_item_wrapper}>
                    <MenuOutlined style={{fontSize: '24px'}} />
                    <span onClick={showDrawer}>Menu</span>
                </div>
            </div>
            <Drawer
                title={<MenuOutlined style={{fontSize: '24px'}} />}
                placement={'bottom'}
                closable={true}
                onClose={onClose}
                open={open}
                height={'150vw'}
                destroyOnClose={true}
            >
                <div className={Styles.menu_item_drawer_wrapper}>
                
                    <div onClick={onClose} className={location.pathname === '/dashboard'?  `${Styles.menu_item_drawer} ${Styles.active}` : `${Styles.menu_item_drawer}`}>
                        <AppstoreOutlined style={{fontSize: '32px'}} />
                        <Link to="/dashboard">Dashboard</Link>
                    </div>

                    <div onClick={onClose} className={location.pathname === '/order-management'?  `${Styles.menu_item_drawer} ${Styles.active}` : `${Styles.menu_item_drawer}`}>
                        <ShoppingCartOutlined style={{fontSize: '32px'}} />
                        <Link to="/order-management">Orders</Link>
                    </div>

                    <div onClick={onClose} className={location.pathname === '/track-order'?  `${Styles.menu_item_drawer} ${Styles.active}` : `${Styles.menu_item_drawer}`}>
                        <FieldTimeOutlined style={{fontSize: '32px'}} />
                        <Link to="/track-order">Track Order</Link>
                    </div>

                    <div onClick={onClose} className={location.pathname === '/our-products'?  `${Styles.menu_item_drawer} ${Styles.active}` : `${Styles.menu_item_drawer}`}>
                        <BlockOutlined style={{fontSize: '32px'}} />
                        <Link to="/our-products">Products</Link>
                    </div>

                    <div onClick={onClose} className={location.pathname === '/ndr-management'?  `${Styles.menu_item_drawer} ${Styles.active}` : `${Styles.menu_item_drawer}`}>
                        <ExceptionOutlined style={{fontSize: '32px'}} />
                        <Link to="/ndr-management">NDR</Link>
                    </div>

                    <div onClick={onClose} className={location.pathname === '/reports'?  `${Styles.menu_item_drawer} ${Styles.active}` : `${Styles.menu_item_drawer}`}>
                        <ReconciliationOutlined style={{fontSize: '32px'}} />
                        <Link to="/reports">Reports</Link>
                    </div>

                    <div onClick={onClose} className={location.pathname === '/invoice-reports'?  `${Styles.menu_item_drawer} ${Styles.active}` : `${Styles.menu_item_drawer}`}>
                        <FileOutlined style={{fontSize: '32px'}} />
                        <Link to="/invoice-reports">Invoices</Link>
                    </div>

                    <div onClick={onClose} className={location.pathname === '/remittances'?  `${Styles.menu_item_drawer} ${Styles.active}` : `${Styles.menu_item_drawer}`}>
                        <DollarOutlined style={{fontSize: '32px'}} />
                        <Link to="/remittances">Remittances</Link>
                    </div>


                    <div onClick={onClose} className={location.pathname === '/rate-calculator'?  `${Styles.menu_item_drawer} ${Styles.active}` : `${Styles.menu_item_drawer}`}>
                        <CalculatorOutlined style={{fontSize: '32px'}} />
                        <Link to="/rate-calculator">Rate Calculator</Link>
                    </div>                    

                    <div onClick={openSupportLink} className={Styles.menu_item_drawer}>
                        <CustomerServiceFilled style={{fontSize: '32px'}} />
                        <Link to="/our-products">Support</Link>
                    </div>
                </div>

                {localStorage.getItem('integrations') !== null && (
                    <div className={Styles.mystores_wrapper}>

                        {JSON.parse(localStorage.getItem('integrations'))?.length > 0 && (
                            JSON.parse(localStorage.getItem('integrations')).map((store, index)=>{
                                if(store?.company_name === 'SHOPIFY'){
                                    return(
                                        <Link onClick={onClose} to='/my-stores/shopify' className={Styles.store_wrapper}>
                                            <img src={ShopifyLogo} alt="Shopify" />
                                        </Link>
                                    )
                                }
                            })
                        )}

                        {JSON.parse(localStorage.getItem('integrations'))?.length > 0 && (
                            JSON.parse(localStorage.getItem('integrations')).map((store, index)=>{
                                if(store?.company_name === 'WOOCOMMERCE'){
                                    return(
                                        <Link onClick={onClose} to='/my-stores/woo-commerce' className={Styles.store_wrapper}>
                                            <img src={WooComLogo} alt="Woo-Commerce" />
                                        </Link>
                                    )
                                }
                            })
                        )}

                        {JSON.parse(localStorage.getItem('integrations'))?.length > 0 && (
                            JSON.parse(localStorage.getItem('integrations')).map((store, index)=>{
                                if(store?.company_name === 'UNICOMMERCE'){
                                    return(
                                        <Link onClick={onClose} to='/my-stores/unicommerce' className={Styles.store_wrapper}>
                                            <img src={UnicomLogo} alt="UniCommerce" />
                                        </Link>
                                    )
                                }
                            })
                        )}

                        
                        {JSON.parse(localStorage.getItem('integrations'))?.length > 0 && (
                            JSON.parse(localStorage.getItem('integrations')).map((store, index)=>{
                                if(store?.company_name === 'VINCULUM'){
                                    return(
                                        <Link onClick={onClose} to='/my-stores/vinculum' className={Styles.store_wrapper}>
                                            <img src={VinculumLogo} alt="UniCommerce" />
                                        </Link>
                                    )
                                }
                            })
                        )}
                    </div>
                )}

                
            </Drawer>
        </>
    );
}

export default MobileMenu;
