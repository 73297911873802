import React, { useState, useContext, useEffect  } from 'react';
import { useNavigate } from 'react-router-dom';
import Styles from './Loginas.module.css';
import { Form, message, Button } from 'antd';
import AuthContext from '../../../context/auth-context';
import * as authService from '../../../services/authService';
import CustomerSelect from './components/CustomerSelect';

const Loginas = () => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const authContext = useContext(AuthContext);
    const navigate = useNavigate();
    const [selectedCustomerId, setSelectedCustomerId] = useState(-99);
    const [selectedUserId, setSelectedUserId] = useState(-99);
    const [customersData, setCustomersData] = useState(null);

    const getCustomersData = async() => {
      try{
          const resp = await authService.GET_CUSTOMER_LIST();
          //console.log(resp);
          setCustomersData(resp);
      }
      catch(error){
          console.log(error);
      }   
    }

    useEffect(()=>{
      getCustomersData();
    },[]);

    const handleCustomerSelect = (customerId) => {
      console.log('Selected Customer ID:', customerId);
      setSelectedCustomerId(customerId);
      customersData.forEach(element => {
        if(element.id === customerId){
          setSelectedUserId(element.user_id);
        }
      });
    };

    const handleLogin = async (values) => {
        const { userId } = values;
        authContext.onLoginas(selectedUserId);
        console.log(authContext.isLogin);
        setLoading(true);
        if(Number(localStorage.getItem('isLoggedIn'))){
          setLoading(false);
          const isEmployee = Number(localStorage.getItem('isemployee'));
          if (Number(isEmployee) === 0) {
            navigate('../layouts/MembersLayout');
          } 
          else if (Number(isEmployee) === 1) {
            navigate('/admin-dashboard');
          }
          message.success('Login successful'); 
        }
        if(!authContext.isLogin){
          setLoading(false);
        }
        
    };

    return (
        <div className={Styles.loginContainer}>
          <Form
            form={form}
            name="loginasForm"
            onFinish={handleLogin}
            className={Styles.loginForm}
          >
            <Form.Item
              name="user_id"
            >
              <div>
                <p>Select a Customer</p>
                <CustomerSelect customers={customersData} onSelect={handleCustomerSelect} />
              </div>
            </Form.Item>

            <Form.Item>
              {loading && (
                <Button type="primary" loading className={Styles.loginButton}>
                  Logging you in...
                </Button>
              )}
              {!loading && (
                  <Button type="primary" htmlType="submit" className={Styles.loginButton}>
                    Log in
                  </Button>
              )}
              
            </Form.Item>            
          </Form>
        </div>
    );



}

export default Loginas;

