import React, { useState, useContext  } from 'react';
import { useNavigate } from 'react-router-dom';
import Styles from './Login.module.css';
import { Form, Input, Button, Checkbox, Modal, message } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import AuthContext from '../../../context/auth-context';
import * as authService from '../../../services/authService';

const Login = () => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [forgotPasswordModalVisible, setForgotPasswordModalVisible] = useState(false);
    const [otpSent, setOtpSent] = useState(false);
    const authContext = useContext(AuthContext);
    const navigate = useNavigate();

    const handleLogin = async (values) => {
        const { mobileNumber, password } = values;
        authContext.onLogin(mobileNumber, password);
        console.log(authContext.isLogin);
        setLoading(true);
        if(Number(localStorage.getItem('isLoggedIn'))){
          setLoading(false);
          const isEmployee = Number(localStorage.getItem('isemployee'));
          if (Number(isEmployee) === 0) {
            navigate('../layouts/MembersLayout');
          } 
          else if (Number(isEmployee) === 1) {
            navigate('/admin-dashboard');
          }
          message.success('Login successful'); 
        }
        if(!authContext.isLogin){
          setLoading(false);
        }
        
    };
    
    const showForgotPasswordModal = () => {
        setForgotPasswordModalVisible(true);
        setOtpSent(false);
    };

    const handleForgotPassword = async (values) => {
        try {
          const { mobile } = values;
          const res = await authService.SEND_PASSWORD_RESET_OTP(mobile);
          if(res?.error){
            // Display error message
            message.error(res?.error);            
          }else{
            // Display success message
            message.success('An OTP has been sent to your registered mobile number. Please login using that OTP and change your password for future operations.');
          }
          setOtpSent(true);
          setForgotPasswordModalVisible(false);
        } catch (error) {
          // Display error message
          message.error(error.message);
        }
    };

    const handleCancelForgotPassword = () => {
        setForgotPasswordModalVisible(false);
    };

    return (
        <div className={Styles.loginContainer}>
          <Form
            form={form}
            name="loginForm"
            initialValues={{ remember: true }}
            onFinish={handleLogin}
            className={Styles.loginForm}
          >
            <Form.Item
              name="mobileNumber"
              rules={[
                { required: true, message: 'Please enter your 10-digit mobile number!' },
                { len: 10, message: 'Mobile number must be 10 digits!' },
                { pattern: /^[0-9]*$/, message: 'Please enter only numbers!' }
              ]}
            >
              <Input placeholder="10-digit Mobile Number" />
            </Form.Item>
    
            <Form.Item
              name="password"
              rules={[{ required: true, message: 'Please enter your password!' }]}
            >
              <Input.Password
                placeholder="Password"
                iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
              />
            </Form.Item>
    
            <Form.Item>
              <Form.Item name="remember" valuePropName="checked" noStyle>
                <Checkbox className={Styles.left_margin}>Remember me</Checkbox>
              </Form.Item>
    
              <a className={Styles.forgotPassword} onClick={showForgotPasswordModal}>
                Forgot Password
              </a>
            </Form.Item>
    
            <Form.Item>
              {loading && (
                <Button type="primary" loading className={Styles.loginButton}>
                  Logging you in...
                </Button>
              )}
              {!loading && (
                  <Button type="primary" htmlType="submit" className={Styles.loginButton}>
                    Log in
                  </Button>
              )}
              
            </Form.Item>
          </Form>
    
          {/* Forgot Password Modal */}
          <Modal
            title="Forgot Password"
            open={forgotPasswordModalVisible}
            onCancel={handleCancelForgotPassword}
            footer={null}
          >
              {otpSent && (
                <div className={Styles.otpSentMessage}>
                  An OTP has been sent to your registered mobile number.
                  Please login using that OTP and change your password for future operations.
                </div>
              )}
              {!otpSent && (
                <Form name="forgotPasswordForm" onFinish={handleForgotPassword}>
                  <Form.Item
                    name="mobile"
                    rules={[
                      { required: true, message: 'Please enter your registered mobile number!' },
                      { len: 10, message: 'Mobile number must be 10 digits!' },
                    ]}
                  >
                    <Input placeholder="Registered Mobile Number" />
                  </Form.Item>
                  <Form.Item>
                    <Button type="primary" htmlType="submit">
                      Submit
                    </Button>
                  </Form.Item>
                </Form>
              )}
          </Modal>
        </div>
    );



}

export default Login;

