import React from 'react';
import Styles from './Footer.module.css';

const Footer = () => {
  return (
    <footer className={Styles.footer_wrapper}>
      <div className={Styles.footer_copyright}>&copy; 2023 - <strong>OPTNSHIP</strong> | OPTNSHIP LOGISTICS PVT. LTD.</div>
      <div className={Styles.footer_develop}>Designed &amp; Developed by <a href='https://assamdigital.com' target='_blank'>Assam Digital</a></div>
    </footer>
  );
};

export default Footer;
