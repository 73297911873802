export const getDisplayDate = (date, showDay) => {
    let original = new Date(date).toDateString();
    let display_date_array = original.split(" ");
    const weekday = display_date_array[0];
    const month = display_date_array[1];
    const day = display_date_array[2];
    const year = display_date_array[3];
    if(showDay){
        const display_date = `${day} ${month}, ${year} - ${weekday}`;
        return display_date;
    }else{
        const display_date = `${day} ${month}, ${year}`;
        return display_date;
    }
}

export const getDateInDbFormat = (dateString) => {
    let date = dateString;
    const offset = date.getTimezoneOffset();
    date = new Date(date.getTime() - (offset*60*1000));
    return (date.toISOString().split('T')[0]);
}

export const conver24to12HrsTimeFormat = (timeString) => {
    const timeString12hr = new Date('1984-07-12T' + timeString + 'Z')
    .toLocaleTimeString('en-US',
        {timeZone:'UTC',hour12:true,hour:'numeric',minute:'numeric'}
    );
    return timeString12hr;
}

export const getIdsFromAwbs = (arr1, data) => {
    const awbSet = new Set(arr1);
    const idArray = [];
  
    // Check if data is an array or an object
    const dataArray = Array.isArray(data) ? data : Object.values(data);
  
    for (const item of dataArray) {
      if (item && item.awb && awbSet.has(item.awb)) {
        idArray.push(item.id);
        awbSet.delete(item.awb); // Remove the found AWB from the set for efficiency
      }
  
      // Break early if all AWBs are found
      if (awbSet.size === 0) {
        break;
      }
    }
  
    return idArray;
};
